import $http from '../utils/http';

/*
  $http (url, data, method, header)
*/

/**
 * Retrieves the list of businesses.
 *
 * @param {string} token - The authorization token.
 * @returns {Promise} - A promise resolving to the business list.
 */
const getBusinessList = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/business`, null, 'GET', header);
};

/**
 * Retrieves the list of client users.
 *
 * @param {string} token - The authorization token.
 * @returns {Promise} - A promise resolving to the list of client users.
 */
const getClientUsers = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/clients`, null, 'GET', header);
};

/**
 * Retrieves a specific business client user by their ID.
 *
 * @param {string} token - The authorization token.
 * @param {string} userID - The ID of the user to retrieve.
 * @returns {Promise} - A promise resolving to the user details.
 */
const getBusinessClientUserByID = (token, userID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/business/${userID}`, null, 'GET', header);
};

/**
 * Creates a new admin user.
 *
 * @param {string} token - The authorization token.
 * @param {Object} data - The data for the new admin user.
 * @returns {Promise} - A promise resolving to the created admin user.
 */
const createAdminUser = (token, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/admins/`, data, 'POST', header);
};

/**
 * Cancels the subscription of a business.
 *
 * @param {string} token - The authorization token.
 * @param {string} businessID - The ID of the business whose subscription is to be canceled.
 * @returns {Promise} - A promise resolving to the cancellation result.
 */
const cancelSubscription = (token, businessID) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token } : null;
  return $http(`/api/business/${businessID}/cancel-subscription`, null, 'PUT', header);
};

/**
 * Updates the details of a business.
 *
 * @param {string} token - The authorization token.
 * @param {string} businessID - The ID of the business to update.
 * @param {Object} businessData - The new data for the business.
 * @param {Object} [query] - Optional query parameters.
 * @returns {Promise} - A promise resolving to the updated business details.
 */
const updateBusiness = (token, businessID, businessData, query) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token } : null;
  query = query && typeof query === 'object' ? `?${new URLSearchParams(query).toString()}` : '';
  console.log(query);
  return $http(`/api/business/${businessID}${query}`, businessData, 'PUT', header);
};

/**
 * Deletes an admin user.
 *
 * @param {string} token - The authorization token.
 * @param {string} adminID - The ID of the admin user to delete.
 * @returns {Promise} - A promise resolving to the deletion result.
 */
const deleteAdminUser = (token, adminID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/admins/${adminID}`, '', 'DELETE', header);
};

/**
 * Retrieves a business by its ID.
 *
 * @param {string} token - The authorization token.
 * @param {string} businessId - The ID of the business to retrieve.
 * @returns {Promise} - A promise resolving to the business details.
 */
const getBusinessById = (token, businessId) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/business/${businessId}`, null, 'GET', header);
};

export { getClientUsers, getBusinessList, getBusinessClientUserByID, cancelSubscription, createAdminUser, updateBusiness, deleteAdminUser, getBusinessById };
